import "./SummaryCard.css";
import { Link } from "react-router-dom";

const SummaryCard = () => {
  return (
    <div className="pricing">
      <div className="summary-card-container">
        <div className="summary-card">
          <h3>SMART TRAIN</h3>
          <span className="bar"></span>
          <p className="card-title">IOT</p>
          <p>- 2000 Variable -</p>
          <p>- Real Time Monitoring -</p>
          <p>- Extensive Data Analysis -</p>
          <p>- Mobile And Web Platforms -</p>
          <Link to="/contact" className="sum-btn">
            REVIEW
          </Link>
        </div>
        <div className="summary-card">
          <h3>IMS</h3>
          <span className="bar"></span>
          <p className="card-title">IOT</p>
          <p>- 12 Vehicle Track -</p>
          <p>- Real Time Location and Speed -</p>
          <p>- Location/Acceleration Analysis -</p>
          <p>- Mobile And Web Platforms -</p>
          <Link to="/contact" className="sum-btn">
            REVIEW
          </Link>
        </div>
        <div className="summary-card">
          <h3>Gearbox Test Bench</h3>
          <span className="bar"></span>
          <p className="card-title">TEST SYSTEM</p>
          <p>- 6 Sensor -</p>
          <p>- Real Time Data -</p>
          <p>- Fault Detection -</p>
          <p>- Extensive Data Analysis -</p>
          <Link to="/contact" className="sum-btn">
            REVIEW
          </Link>
        </div>
        <div className="summary-card">
          <h3>Sensor Test Bench</h3>
          <span className="bar"></span>
          <p className="card-title">TEST SYSTEM</p>
          <p>- Test 2 Type Sensor -</p>
          <p>- Real Time Data -</p>
          <p>- Fault Detection -</p>
          <p>- Extensive Data Analysis -</p>
          <Link to="/contact" className="sum-btn">
            REVIEW
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SummaryCard;
