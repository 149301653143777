import "./Sidebar2.css";
import { useNavigate, Link } from "react-router-dom";
import { useContext } from "react";
import AuthContext from "../context/AuthProvider";
import tmsLogo from "../assets/images/tms_logo_png.png";

import {
  BiHome,
  // BiBookAlt,
  // BiMessage,
  BiSolidReport,
  BiStats,
  BiTask,
  // BiHelpCircle,
  BiLogOut,
} from "react-icons/bi";
import { FaGlobeAmericas, FaBrain } from "react-icons/fa";
import Badge from "./Badge";

const Sidebar2 = () => {
  const { setAuth } = useContext(AuthContext);
  const navigate = useNavigate();

  const logout = () => {
    setAuth({});
    navigate("/");
  };

  return (
    <div className="menu">
      <div className="logo">
        {/* <BiBookAlt className="icon" /> */}

        <img
          src={tmsLogo}
          alt="tms logo"
          style={{ width: "100px", height: "40px", cursor: "pointer" }}
        />

        {/* <h2>IMS</h2> */}
      </div>

      <div className="menu--list">
        <Link to="#" className="item active">
          <BiHome className="icon" />
          Dashboard
        </Link>
        <Link to="/attribute" className="item">
          <FaGlobeAmericas className="icon" />
          Project information
        </Link>
        <Link to="/anomaly" className="item">
          <FaBrain className="icon" />
          Anomaly Insights <Badge text="Admin" type="admin" />
        </Link>
        <Link to="/reports" className="item">
          <BiSolidReport className="icon" />
          Reports <Badge text="Admin" type="admin" />
        </Link>
        <Link to="/dailystate" className="item">
          <BiStats className="icon" />
          Daily State <Badge text="Admin" type="admin" />
        </Link>
        {/* <Link to="#" className="item">
          <BiMessage className="icon" />
          Message
        </Link>
        <Link to="#" className="item">
          <BiHelpCircle className="icon" />
          Help
        </Link> */}

        <Link to="#" className="item" onClick={logout}>
          <BiLogOut className="icon" />
          Logout
        </Link>
      </div>
    </div>
  );
};

export default Sidebar2;
