import "./DeviceList.css";
import Image1 from "../assets/images/train-home.jpg";
import MapComponent2 from "./MapComponent2";

const devices = [
  {
    image: Image1,
    name: "sensor_867204060128758",
    vehicle: "65007-TCB",
    threshold: "2G",
    active: "Active",
  },
  {
    image: Image1,
    name: "sensor_867204060495892",
    vehicle: "65004-TCB",
    threshold: "2G",
    active: "Active",
  },
  {
    image: Image1,
    name: "sensor_?",
    vehicle: "65009",
    threshold: "2G",
    active: "Not Active",
  },
];

const DeviceList = ({ location }) => {
  return (
    <div className="device--list">
      <div className="map--container">
        {/* Map Component */}
        <MapComponent2 location={location} />
      </div>

      <div className="list--container">
        {devices.map((device) => (
          <div className="list" key={device.name}>
            <div className="device--detail">
              <img src={device.image} alt={device.name} />
              <h2>{device.vehicle}</h2>
            </div>
            <span>Threshold: {device.threshold}</span>
            <span>{device.active}</span>
            <span className="teacher--todo">:</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DeviceList;
