// import React, { useState, useEffect } from "react";

// import SideBar from "../components/SideBar";
// import MapAndDataTypes from "../components/MapAndDataTypes";
// import NavigationBar from "../components/NavigationBar";

// function Dashboard({ showTrainList, setShowTrainList }) {
//   const API_URL = "http://localhost:4000/Track_Device2/latestLocation";

//   const [isLoading, setIsLoading] = useState(true);
//   const [fetchError, setFetchError] = useState(null);
//   const [deviceInfo, setDeviceInfo] = useState("");
//   const [locationTime, setLocationTime] = useState(null);

//   useEffect(() => {
//     const fetchLocation = async () => {
//       try {
//         const response = await fetch(API_URL);
//         if (!response.ok) throw Error("Did not receive data");
//         const locationInfo = await response.json();
//         console.log(locationInfo);

//         const locDate = new Date(locationInfo.timestamp * 1000);
//         const options = {
//           timeZone: "Europe/Istanbul",
//           year: "numeric",
//           month: "numeric",
//           day: "numeric",
//           hour: "numeric",
//           minute: "numeric",
//           second: "numeric",
//         };
//         const formattedData = locDate.toLocaleDateString("tr-TR", options);
//         console.log(formattedData);
//         setLocationTime(formattedData);

//         setDeviceInfo(locationInfo);
//         setFetchError(null);
//       } catch (err) {
//         setFetchError(err.message);
//       } finally {
//         setIsLoading(false);
//       }
//     };
//     setTimeout(() => {
//       (async () => await fetchLocation())();
//     }, 2000);
//   }, []);

//   return (
//     <>
//       <NavigationBar />
//       <div
//         style={{
//           display: "flex",
//           flexDirection: "row",
//           flex: 1,
//         }}
//       >
//         <SideBar
//           showTrainList={showTrainList}
//           setShowTrainList={setShowTrainList}
//         />
//         <MapAndDataTypes
//           isLoading={isLoading}
//           fetchError={fetchError}
//           locationTime={locationTime}
//           deviceInfo={deviceInfo}
//         />
//       </div>
//     </>
//   );
// }

// export default Dashboard;

import Sidebar2 from "../components/Sidebar2";
import Content from "../components/Content";
import Profile from "../components/Profile";
import { useEffect, useState } from "react";

const Dashboard = () => {
  const [device, setDevice] = useState("Device_5892");
  const [location, setLocation] = useState([]);
  const [locationTime, setLocationTime] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [fetchError, setFetchError] = useState(null);
  const [color, setColor] = useState("#16697a");

  const API_URL = `https://ims-backend-6yyp.onrender.com/${device}/tmsDeviceLocation`;

  useEffect(() => {
    const fetchLocation = async () => {
      try {
        const response = await fetch(API_URL);
        if (!response.ok) throw Error("Did not receive expected data");
        const locationItems = await response.json();
        console.log(locationItems);
        console.log(device);
        const locDate = new Date(locationItems.timestamp * 1000);
        const options = {
          timeZone: "Europe/Istanbul",
          year: "numeric",
          month: "numeric",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
        };
        const formattedData = locDate.toLocaleDateString("tr-TR", options);
        console.log(formattedData);
        setLocationTime(formattedData);
        setLocation(locationItems);
        setColor("#ef8354");

        setTimeout(() => {
          setColor("#16697a");
        }, 1000);

        setFetchError(null);
      } catch (err) {
        setFetchError(err.message);
      } finally {
        setIsLoading(false);
      }
    };
    const intervalId = setInterval(() => {
      (async () => await fetchLocation())();
    }, 8000);

    return () => clearInterval(intervalId);
  }, [device]);

  return (
    <div className="dashboard">
      <Sidebar2 />
      <div className="dashboard-content">
        <Content
          device={device}
          setDevice={setDevice}
          location={location}
          locationTime={locationTime}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          color={color}
        />
        <Profile />
      </div>
    </div>
  );
};

export default Dashboard;
