import "./WorkCard.css";
import { NavLink } from "react-router-dom";

import CafImg from "../assets/images/Caf_Smart_Train.jpg";
import GearBox from "../assets/images/gearbox.jpg";
import SensorTest from "../assets/images/sensor_test_bench.jpg";

const WorkCard = () => {
  return (
    <div className="work-container">
      <h1 className="project-heading">Projects</h1>
      <div className="project-container">
        <div className="project-card">
          <img src={GearBox} alt="caf-img" />
          <h2 className="project-title">Gearbox Test Bench</h2>
          <div className="pro-details">
            <p>Gearbox Test Bench project description</p>
            <div className="pro-btns">
              <NavLink to="url.com" className="work-but">
                VIEW
              </NavLink>
              <NavLink to="url.com" className="work-but">
                SOURCE
              </NavLink>
            </div>
          </div>
        </div>
        <div className="project-card">
          <img src={CafImg} alt="caf-img" />
          <h2 className="project-title">Smart Train</h2>
          <div className="pro-details">
            <p>Smart Train project description</p>
            <div className="pro-btns">
              <NavLink to="url.com" className="work-but">
                VIEW
              </NavLink>
              <NavLink to="url.com" className="work-but">
                SOURCE
              </NavLink>
            </div>
          </div>
        </div>

        <div className="project-card">
          <img src={SensorTest} alt="caf-img" />
          <h2 className="project-title">Sensor Test Bench</h2>
          <div className="pro-details">
            <p>Sensor Test Bench project description</p>
            <div className="pro-btns">
              <NavLink to="url.com" className="work-but">
                VIEW
              </NavLink>
              <NavLink to="url.com" className="work-but">
                SOURCE
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkCard;
