import "./Footer.css";
import {
  FaHome,
  FaPhone,
  FaMailBulk,
  FaFacebook,
  FaTwitter,
  FaLinkedin,
} from "react-icons/fa";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-container">
        <div className="left">
          <div className="location">
            <FaHome size={30} style={{ color: "#fff", marginRight: "2rem" }} />
            <div>
              <p>Etimesgut YHT Araç Bakım Atölye Müdürlüğü Ayaş Yolu Bulvarı</p>
              <p>Etiler Mah. No:89/C Ankara - TÜRKİYE</p>
            </div>
          </div>
          <div className="phone">
            <h4>
              <FaPhone
                size={20}
                style={{ color: "#fff", marginRight: "2rem" }}
              />
              +90 312 211 24 45
            </h4>
          </div>
          <div className="email">
            <h4>
              <FaMailBulk
                size={20}
                style={{ color: "#fff", marginRight: "2rem" }}
              />
              info@tmstechnic.com
            </h4>
          </div>
        </div>
        <div className="right">
          <h4>About the company</h4>
          <p>
            TMS company, besides being the first and only local maintenance
            contractor for the High Speed Rail sector in Turkey, also is the
            first private enterprise in this sector which has international
            validity 'Entity in Charge of Maintenance' certificate issued by
            European Railway Agency (ERA) .
          </p>
          <div className="social">
            <FaFacebook
              size={30}
              style={{ color: "#fff", marginRight: "1rem" }}
            />
            <FaTwitter
              size={30}
              style={{ color: "#fff", marginRight: "1rem" }}
            />
            <FaLinkedin
              size={30}
              style={{ color: "#fff", marginRight: "1rem" }}
            />
            {/* https://www.linkedin.com/company/tms-tren-bakim-onarim-a-%C5%9F/mycompany/ */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
