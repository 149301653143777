import "./Modal.css";

import { useRef, forwardRef, useImperativeHandle } from "react";

const Modal = forwardRef(function Modal({ children, buttonCaption }, ref) {
  const dialog = useRef();
  useImperativeHandle(ref, () => {
    return {
      open() {
        dialog.current.showModal();
      },
    };
  });
  return (
    <dialog ref={dialog} className="dialog-style">
      {children}
      <form method="dialog" className="form-style">
        <button className="dialog-button-style">{buttonCaption}</button>
      </form>
    </dialog>
  );
});

export default Modal;
