import "./App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Dashboard from "./Routes/Dashboard";
import Reports from "./Routes/Reports";
import Layout from "./components/Layout";
import Home from "./Routes/Home";
import Project from "./Routes/Project";
import Contact from "./Routes/Contact";
import About from "./Routes/About";
import Login from "./Routes/Login";
import DailyState from "./Routes/DailyState";
import RequireAuth from "./components/RequireAuth";
import Unauthorized from "./components/Unauthorized";
import Attributes from "./Routes/Attribute";
import ErrorPage from "./Routes/Error";
import Anomaly from "./Routes/Anomaly";
import LandingPage from "./Routes/LandingPage";

const ROLES = {
  User: 2001,
  Editor: 1984,
  Admin: 5150,
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    errorElement: <ErrorPage />,
    children: [
      { path: "/", element: <LandingPage /> },
      { path: "/home", element: <Home /> },
      { path: "/login", element: <Login /> },
      { path: "/project", element: <Project /> },
      { path: "/contact", element: <Contact /> },
      { path: "/about", element: <About /> },
      { path: "/unauthorized", element: <Unauthorized /> },

      // Protected Routes
      {
        element: <RequireAuth allowedRoles={[ROLES.Admin]} />,
        children: [{ path: "/anomaly", element: <Anomaly /> }],
      },
      {
        element: <RequireAuth allowedRoles={[ROLES.Admin]} />,
        children: [{ path: "/reports", element: <Reports /> }],
      },
      {
        element: <RequireAuth allowedRoles={[ROLES.User]} />,
        children: [{ path: "/dashboard", element: <Dashboard /> }],
      },
      {
        element: <RequireAuth allowedRoles={[ROLES.User]} />,
        children: [{ path: "/attribute", element: <Attributes /> }],
      },
      {
        element: <RequireAuth allowedRoles={[ROLES.Editor, ROLES.Admin]} />,
        children: [{ path: "/dailystate", element: <DailyState /> }],
      },
    ],
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
