import React, { useEffect } from "react";
import { MapContainer, TileLayer, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import "leaflet.heat";

function HeatmapLayer({ points }) {
  const map = useMap();

  useEffect(() => {
    const heatLayer = L.heatLayer(points, {
      radius: 20, // Isı haritası noktalarının yarıçapı
      blur: 15, // Isı haritasının bulanıklık seviyesi
      maxZoom: 16,
      max: 1.0, // Isı haritası için maksimum yoğunluk değeri
    }).addTo(map);

    return () => {
      map.removeLayer(heatLayer);
    };
  }, [map, points]);

  return null;
}

function AnomalyHeatmap({ anomalies }) {
  // Anomalilerin verilerini heatmap için uygun formata dönüştürme
  const points = anomalies.map((anomaly) => [
    anomaly.latitude,
    anomaly.longitude,
    anomaly.value, // Heatmap yoğunluğu için değer
  ]);

  return (
    <MapContainer
      center={[anomalies[0].latitude, anomalies[0].longitude]}
      zoom={8}
      style={{ height: "500px", width: "100%" }}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      <HeatmapLayer points={points} />
    </MapContainer>
  );
}

export default AnomalyHeatmap;
