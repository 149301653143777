import "./Unauthorized.css";
import { useNavigate } from "react-router";
import { BsSignStopFill } from "react-icons/bs";

const Unauthorized = () => {
  const navigate = useNavigate();

  const goBack = () => navigate(-1);

  return (
    <section className="unauth-container">
      <h1 className="unauth-title">Unauthorized</h1>
      <BsSignStopFill className="unauth-icon" />
      <br />
      <p className="unauth-paragraph">
        You do not have access to the requested page
      </p>
      <div>
        <button className="unauth-button" onClick={goBack}>
          Go Back
        </button>
      </div>
    </section>
  );
};

export default Unauthorized;
