import "./AboutContent.css";
import { Link } from "react-router-dom";

// import TmsCaf2 from "../assets/images/train-home2.jpg";
// import TmsCaf3 from "../assets/images/train-home3.jpg";
import TmsMotor from "../assets/images/tms-motor.jpg";
import TmsSignal from "../assets/images/signalling.jpg";

const AboutContent = () => {
  return (
    <div className="about">
      <div className="left">
        <h1>Who are We?</h1>
        <p>
          It is the first private domestic institution in Turkey with the
          competence to perform maintenance and repair services for high-speed
          trains.
        </p>
        <Link to="/contact">
          <button className="btn">Contact</button>
        </Link>
      </div>
      <div className="right">
        <div className="img-container">
          <div className="img-stack top">
            <img className="img" src={TmsSignal} alt="tms-caf" />
          </div>
          <div className="img-stack bottom">
            <img className="img" src={TmsMotor} alt="tms-signal" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutContent;
