import React, { useEffect, useRef } from "react";
import "leaflet.heat";
import L from "leaflet";
import * as d3 from "d3";

const MapExampleZ = ({ heatmapData }) => {
  const mapRef = useRef(null); // Harita referansını oluşturun

  useEffect(() => {
    if (!mapRef.current) {
      const map = L.map("map").setView([39.9572589, 32.6475256], 6);
      mapRef.current = map;
      L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        attribution:
          '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      }).addTo(map);
    }

    const map = mapRef.current;

    // Renk skalasını belirle
    const gradient = {
      0: "#003554", // koyu mavi - açık mavi - yeşil -sarı- açık turuncu - kırmızı
      0.27: "#86bbd8", // açık mavi
      0.55: "#caffbf", // yeşil
      0.79: "yellow", // sarı
      0.92: "#faa307", // koyu turuncu
      1: "#dd3333", //  koyu kırmızı
    };

    // Renk skalasını belirle (Örneğin: d3.interpolateViridis() gibi)
    const colorScale = getColorScale(heatmapData);

    const heatLayer = L.heatLayer([], { radius: 20, gradient }).addTo(map);

    const points = heatmapData.map(function (p) {
      return [p.latitude, p.longitude, p.maxZ];
    });

    heatLayer.setLatLngs(points);

    heatmapData.forEach((point) => {
      const color = getColorFromValue(point.maxZ, colorScale);
      const radius = getRadiusFromValue(point.maxZ); // Dinamik daire boyutu

      L.circle([point.latitude, point.longitude], {
        fillColor: color,
        color: color,
        weight: 1,
        fillOpacity: 0.25,
        radius: radius,
      })
        .addTo(map)
        .bindPopup(`Z Value: ${point.maxZ.toFixed(2)}`); // Popup içeriği güncellendi
    });

    return () => {
      // Harita bileşeni unmount olduğunda haritayı yok et
      // map.remove();  // Yorum satırına alındı
    };
  }, [heatmapData]);

  const getColorScale = (data) => {
    const maxZValues = data.map((point) => point.maxX);
    const maxZMax = Math.max(...maxZValues);
    const maxZMin = Math.min(...maxZValues);

    return d3.scaleLinear().domain([maxZMin, maxZMax]).range(["white", "red"]);
  };

  const getColorFromValue = (value, colorScale) => {
    return colorScale(value);
  };

  const getRadiusFromValue = (value) => {
    // Dinamik daire boyutu belirleme (örneğin, 10'dan 50'ye lineer artış)
    return 10 + (value / 16) * 40; // Örnek bir formül, gerektiğinde değiştirilebilir
  };

  return <div id="map" style={{ height: "50vh", width: "100%" }}></div>;
};

export default MapExampleZ;
