import React from "react";
import { MapContainer, TileLayer, Circle, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";

function AnomalyCircles({ anomalies }) {
  return (
    <MapContainer
      center={[anomalies[0].latitude, anomalies[0].longitude]}
      zoom={8}
      style={{ height: "500px", width: "100%" }}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      {anomalies.map((anomaly, index) => (
        <Circle
          key={index}
          center={[anomaly.latitude, anomaly.longitude]}
          radius={anomaly.value * 10} // Çemberin boyutunu veriye göre ayarlayabilirsiniz
          fillColor="red"
          color="red"
          fillOpacity={0.5}
        >
          <Popup>
            <strong>Anomaly {index + 1}</strong>
            <br />
            Value: {anomaly.value}
          </Popup>
        </Circle>
      ))}
    </MapContainer>
  );
}

export default AnomalyCircles;
