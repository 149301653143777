import "./NoStateSelected.css";
import noProjectImage from "../assets/images/no-projects.png";
import { useRef, useState } from "react";
import Modal from "./Modal";

const NoStateSelected = ({ onStartProject, allStateData, onSelectState }) => {
  const queryRef = useRef();
  const modal = useRef();
  const [stateData, setStateData] = useState([]);

  const handleQueryFilter = async () => {
    const filterQuery = queryRef.current.value;

    // validation
    if (filterQuery.trim() === "") {
      //show modal error
      modal.current.open();
      return;
    }
    console.log("All States:", allStateData);

    const formattedDate = new Date(filterQuery).toISOString();
    // console.log("Formatted Date: ", formattedDate);

    const foundStates = allStateData.filter(
      (state) => state.dailyDate === formattedDate
    );
    console.log("Query result: ", foundStates);
    setStateData(foundStates);
  };

  return (
    <div className="container-group">
      <div className="no-state-container">
        <img
          src={noProjectImage}
          alt="An empty task list"
          className="no-state-img"
        />
        <h2 className="no-state-selected-title">No State Selected</h2>
        <p className="no-state-selected-parag">
          Select a state or create a new one
        </p>
        <p className="create-parag">
          <button onClick={onStartProject} className="create-state-button">
            Create new state
          </button>
        </p>
      </div>
      <h2 className="main-card-title">Status</h2>
      <div className="main-flex-container">
        <div className="all-state-container">
          <ul>
            {allStateData.map((stateItem, index) => (
              <li key={index}>
                {/* state-card */}
                <div
                  onClick={() => onSelectState(stateItem._id)}
                  className="card-container"
                >
                  <div className="sub-card-container">
                    <div className="cardStyle">
                      <p className="p-view-start">{stateItem.destinationNo}</p>
                      <p className="p-view-end">
                        {stateItem.dailyDate.slice(0, 10)}
                      </p>
                    </div>

                    <div className="cardStyle">
                      <p className="p-view-start">
                        {stateItem.inputDestination} -{" "}
                        {stateItem.outputDestination}
                      </p>
                      <p className="p-view-end">
                        {stateItem.inputTime} - {stateItem.outputTime}
                      </p>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <Modal ref={modal} buttonCaption="Close">
          <h2 className="modal-header">Invalid Input</h2>
          <p className="modal-paragraph">Make sure you provide a valid date.</p>
        </Modal>
        <div className="main-filter-container">
          <div className="filter-container">
            <h4 className="filter-container-title">Filter State</h4>
            <div className="filter-input-button-group">
              <input
                type="date"
                className="filter-input"
                placeholder="GG/AA/YYYY"
                ref={queryRef}
              />
              <button onClick={handleQueryFilter} className="filter-button">
                Filter
              </button>
            </div>
          </div>
          {/* Filter result Section */}
          {stateData.length !== 0 ? (
            <div
              style={{
                marginTop: "1.5rem",
                backgroundColor: "#fff",
                height: "50vh",
                paddingTop: "1rem",
                borderRadius: "0.375rem",
              }}
            >
              <div className="filter-state-container">
                <ul>
                  {stateData.map((stateItem, index) => (
                    <li key={index}>
                      {/* state-card */}
                      <div
                        onClick={() => onSelectState(stateItem._id)}
                        className="card-container"
                      >
                        <div className="sub-card-container">
                          <div className="cardStyle">
                            <p className="p-view-start">
                              {stateItem.destinationNo}
                            </p>
                            <p className="p-view-end">
                              {stateItem.dailyDate.slice(0, 10)}
                            </p>
                          </div>

                          <div className="cardStyle">
                            <p className="p-view-start">
                              {stateItem.inputDestination} -{" "}
                              {stateItem.outputDestination}
                            </p>
                            <p className="p-view-end">
                              {stateItem.inputTime} - {stateItem.outputTime}
                            </p>
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ) : (
            <div style={{ color: "#fff", margin: "auto" }}>
              Please Select Date
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NoStateSelected;
