import "./SelectedDailystate.css";

const SelectedDailystate = ({ state, onDelete, onCancel }) => {
  const formattedDate = new Date(state.dailyDate).toLocaleDateString("tr-TR", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });

  return (
    <div className="selected-date-container">
      <header className="selected-date-header">
        <div className="selected-date-group">
          <h1 className="selected-title">Set: {state.deviceName}</h1>
          <div className="selected-button-container">
            <button className="selected-cancel-button" onClick={onCancel}>
              Cancel
            </button>
            <button onClick={onDelete} className="selected-delete-button">
              Delete
            </button>
          </div>
        </div>
        <p className="selected-parag">Date: {formattedDate}</p>
        <p className="selected-parag-2">
          Destination Number: {state.destinationNo}
        </p>
        <div className="selected-date-group">
          <p className="dest-group">
            {state.inputDestination} - {state.outputDestination}
          </p>
          <p className="dest-group">
            {state.inputTime} - {state.outputTime}
          </p>
        </div>
      </header>
    </div>
  );
};

export default SelectedDailystate;
