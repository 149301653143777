import React from "react";
import * as d3 from "d3";

const MyColorScale = () => {
  // Özel renk skalası renklerinizi tanımlayın
  const heatmapColors = ["#0000ff", "#00ffff", "#008000", "#ffff00", "#ff0000"];

  // Özel renk skalası oluşturun
  const colorScale = d3
    .scaleSequential()
    .domain([0, heatmapColors.length - 1])
    .interpolator(d3.interpolateRgbBasis(heatmapColors));

  // Renk skalası örneklerini oluşturun
  const colorSamples = Array.from({ length: 5 }).map((_, index) => {
    const value = index / 1; // 0 ile 1 arasında bir değer alır
    return colorScale(value);
  });

  return (
    <div>
      {/* Renk skalası örneklerini gösterin */}
      {colorSamples.map((color, index) => (
        <div
          key={index}
          style={{
            backgroundColor: color,
            width: "40px",
            height: "20px",
            display: "inline-block",
          }}
        />
      ))}
    </div>
  );
};

export default MyColorScale;
