import { ABOUT_TEXT } from "../constants/advertiseData";
import videoSrc from "../assets/videos/2.mp4";
import { motion } from "framer-motion";

const AdvertiseAbout = () => {
  return (
    <div className="about-border">
      <h2 className="about-header">
        About <span className="about-text">Device</span>
      </h2>
      <div className="about-flex-wrap">
        <div className="about-column-1">
          <div className="about-content">
            <motion.video
              whileInView={{ opacity: 1, x: 0 }}
              initial={{ opacity: 0, x: -100 }}
              transition={{ duration: 1 }}
              className="profile-video image-size"
              src={videoSrc}
              autoPlay
              loop
              muted
            />
          </div>
        </div>
        <div className="about-column-2">
          <div className="about-justify-content">
            <motion.p
              whileInView={{ opacity: 1, x: 0 }}
              initial={{ opacity: 0, x: 100 }}
              transition={{ duration: 1 }}
              className="about-content-style"
            >
              {ABOUT_TEXT}
            </motion.p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdvertiseAbout;
