import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import HeroImg2 from "../components/HeroImg2";
import SummaryCard from "../components/SummaryCard";
import WorkCard from "../components/WorkCard";

const Project = () => {
  return (
    <div>
      <Navbar />
      <HeroImg2
        heading="Innovating High-Speed Rail"
        text="Discover the forefront of high-speed rail innovation. Explore our projects shaping the future of swift, reliable, and sustainable train travel."
      />
      <WorkCard />
      <SummaryCard />
      <Footer />
    </div>
  );
};

export default Project;
