import { useState, useRef, useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import axios from "axios";
import ai_7 from "../assets/videos/ai_7.mp4";
import "./AnomalyHero.css";
import AnomalyResult from "./AnomalyResult";

const AnomalyHero = () => {
  const [file, setFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [anomalyData, setAnomalyData] = useState(null); // Anomali verilerini saklamak için
  const videoRef = useRef(null);
  const controls = useAnimation();

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
    setUploadSuccess(false);
    setUploadProgress(0);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!file) {
      alert("Please Select a File.");
      return;
    }

    const formData = new FormData();
    formData.append("excelFile", file);

    try {
      setUploading(true);
      const response = await axios.post(
        "https://ims-backend-6yyp.onrender.com/upload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setUploadProgress(percentCompleted);
          },
        }
      );

      console.log("Anomali tespiti sonuçları:", response.data);

      setAnomalyData(response.data); // Anomali verilerini güncelle
      setUploadSuccess(true);
    } catch (error) {
      console.error("Dosya yükleme işleminde hata oluştu:", error);
      if (error.response) {
        // Sunucu taraflı hata
        console.error("Error data:", error.response.data);
        console.error("Error status:", error.response.status);
        console.error("Error headers:", error.response.headers);
      } else if (error.request) {
        // İstek gönderildi ancak cevap alınamadı
        console.error("Error request:", error.request);
      } else {
        // İstek hazırlanırken hata oluştu
        console.error("General error:", error.message);
      }
      alert("Please visit the 'Reports' page for accurate data.");
    } finally {
      setUploading(false);
    }
  };

  useEffect(() => {
    const video = videoRef.current;

    const handleEnded = () => {
      controls.start({ opacity: 0 }).then(() => {
        video.currentTime = 0;
        video.play();
        controls.start({ opacity: 1 });
      });
    };

    video.addEventListener("ended", handleEnded);
    return () => {
      video.removeEventListener("ended", handleEnded);
    };
  }, [controls]);

  const handleCloseButton = () => {
    setUploadSuccess(false);
    setFile(null);
    setUploadProgress(0);
  };

  return (
    <section className="anomaly-hero">
      <motion.video
        className="anomaly-hero-video"
        autoPlay
        loop
        muted
        ref={videoRef}
        initial={{ opacity: 1 }}
        animate={controls}
        transition={{ duration: 1 }}
      >
        <source src={ai_7} type="video/mp4" />
        Your browser does not support the video tag.
      </motion.video>
      <div className="anomaly-hero-content">
        <div className="anomaly-left-section">
          <motion.h1
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
            className="anomaly-left-section-title"
          >
            Anomaly Detection System
          </motion.h1>
          <motion.p
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, delay: 0.5 }}
          >
            The deep learning model for anomaly detection in vibration data is
            an advanced technology solution used to monitor the performance of
            machines and systems. By learning the normal operating conditions,
            this model can identify deviations and potential issues early on.
            Anomaly detection helps prevent failures, reduce maintenance costs,
            and increase operational efficiency. In modern industrial
            applications, such models are widely used as part of proactive
            maintenance strategies.
          </motion.p>
        </div>
        <div className="anomaly-right-section">
          {!uploadSuccess ? (
            <motion.form
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1, delay: 1 }}
              onSubmit={handleSubmit}
            >
              <input type="file" onChange={handleFileChange} />
              <button
                type="submit"
                disabled={uploading}
                className="anomaly-button"
              >
                {uploading ? "Uploading..." : "Submit Data"}
              </button>
              {file && (
                <div className="anomaly-file-info">
                  <p>File: {file.name}</p>
                </div>
              )}
              {uploading && (
                <div className="anomaly-progress-bar-container">
                  <div
                    className="anomaly-progress-bar"
                    style={{ width: `${uploadProgress}%` }}
                  ></div>
                </div>
              )}
            </motion.form>
          ) : (
            <AnomalyResult data={anomalyData} onClose={handleCloseButton} />
          )}
        </div>
      </div>
    </section>
  );
};

export default AnomalyHero;
