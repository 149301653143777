import "./DailyStateSidebar.css";

const DailyStateSidebar = ({
  onStartProject,
  statesData,
  onSelectState,
  projectSelected,
}) => {
  // Aynı gün veri olup olmadığını kontrol et
  const isDataAvailable = statesData.some(
    (stateItem) =>
      new Date(stateItem.dailyDate).toLocaleDateString("tr-TR") ===
      new Date().toLocaleDateString("tr-TR")
  );

  return (
    <aside className="state-sidebar-container">
      <h2 className="state-title">Daily States</h2>
      <div>
        <button onClick={onStartProject} className="state-add-button">
          + Add State
        </button>
      </div>

      <h4 className="h4-title">
        {new Date().toLocaleDateString("tr-TR")} State
      </h4>

      {isDataAvailable ? (
        <ul>
          {statesData.map((stateItem, index) => {
            let cssClasses = "data-button";

            if (stateItem._id === projectSelected) {
              cssClasses = "data-button-special";
            }

            return (
              <li key={index}>
                {new Date(stateItem.dailyDate).toLocaleDateString("tr-TR") ===
                  new Date().toLocaleDateString("tr-TR") && (
                  <button
                    onClick={() => onSelectState(stateItem._id)}
                    className={cssClasses}
                  >
                    Destination (Sefer): {stateItem.destinationNo}
                  </button>
                )}
              </li>
            );
          })}
        </ul>
      ) : (
        // if data not found in currently day
        <p className="current-states">
          No data available for the selected day.
        </p>
      )}
    </aside>
  );
};

export default DailyStateSidebar;
