// import { BiNotification } from "react-icons/bi";

const ContentHeader = ({ setDevice, device, setIsLoading }) => {
  const handleDeviceChange = (event) => {
    const selectedDevice = event.target.value;
    setDevice(selectedDevice);
    setIsLoading(true);
  };
  return (
    <div className="list--header">
      <h2>Devices</h2>
      <select onChange={(event) => handleDeviceChange(event)} value={device}>
        <option value="Device_5892">65007</option>
        <option value="Device_2495">65004</option>
        {/* <option value="65009">65009</option> */}
      </select>
    </div>
    // <div className="content--header">
    //   <h1 className="header--title">Dashboard</h1>
    //   <div className="header--activity">
    //     {/* <div className="search-box">
    //       <input type="text" placeholder="Search anyting here...." />
    //       <BiSearch className="icon" />
    //     </div> */}

    //     <div className="notify">
    //       <BiNotification className="icon" />
    //     </div>
    //   </div>
    // </div>
  );
};

export default ContentHeader;
