import "./AnomalyResult.css";
import AnomalyMap from "./AnomalyMap";
import AnomalyCircles from "./AnomalyCircles";
import AnomalyHeatmap from "./AnomalyHeatmap";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

function AnomalyResults({ data, onClose }) {
  const {
    anomalyCountZ,
    anomaliesZ,
    anomalyValuesZ,
    anomalyLatitudeZ,
    anomalyLongitudeZ,
    anomalyCountY,
    anomaliesY,
    anomalyValuesY,
    anomalyLatitudeY,
    anomalyLongitudeY,
    anomalyCountX,
    anomaliesX,
    anomalyValuesX,
    anomalyLatitudeX,
    anomalyLongitudeX,
  } = data;

  const anomaliesZAxis = anomalyValuesZ.map((value, index) => ({
    latitude: anomalyLatitudeZ[index],
    longitude: anomalyLongitudeZ[index],
    value,
  }));

  const anomaliesYAxis = anomalyValuesY.map((value, index) => ({
    latitude: anomalyLatitudeY[index],
    longitude: anomalyLongitudeY[index],
    value,
  }));

  const anomaliesXAxis = anomalyValuesX.map((value, index) => ({
    latitude: anomalyLatitudeX[index],
    longitude: anomalyLongitudeX[index],
    value,
  }));

  return (
    <div className="anomaly-results">
      <h2>Anomaly Detection</h2>
      <Tabs>
        <TabList>
          <div className="anomaly-tablist">
            <Tab>Z Axis Anomalies</Tab>
            <Tab>Y Axis Anomalies</Tab>
            <Tab>X Axis Anomalies</Tab>
          </div>
        </TabList>

        <TabPanel>
          <div className="anomaly-info">
            <p>
              Total Data:{" "}
              <strong style={{ color: "#c80036" }}>{anomaliesZ.length}</strong>
            </p>
            <p>
              Anomaly Count:{" "}
              <strong style={{ color: "#c80036" }}>{anomalyCountZ}</strong>
            </p>
          </div>

          <div className="map-container">
            <Tabs>
              <TabList>
                <div className="anomaly-tablist">
                  <Tab>Heatmap</Tab>
                  <Tab>Icons</Tab>
                  <Tab>Circles</Tab>
                </div>
              </TabList>

              <TabPanel>
                <AnomalyHeatmap anomalies={anomaliesZAxis} />
              </TabPanel>
              <TabPanel>
                <AnomalyMap anomalies={anomaliesZAxis} />
              </TabPanel>
              <TabPanel>
                <AnomalyCircles anomalies={anomaliesZAxis} />
              </TabPanel>
            </Tabs>
          </div>
        </TabPanel>

        <TabPanel>
          <div className="anomaly-info">
            <p>
              Total Data:{" "}
              <strong style={{ color: "#c80036" }}>{anomaliesY.length}</strong>
            </p>
            <p>
              Anomaly Count:{" "}
              <strong style={{ color: "#c80036" }}>{anomalyCountY}</strong>
            </p>
          </div>

          <div className="map-container">
            <Tabs>
              <TabList>
                <div className="anomaly-tablist">
                  <Tab>Heatmap</Tab>
                  <Tab>Icons</Tab>
                  <Tab>Circles</Tab>
                </div>
              </TabList>

              <TabPanel>
                <AnomalyHeatmap anomalies={anomaliesYAxis} />
              </TabPanel>
              <TabPanel>
                <AnomalyMap anomalies={anomaliesYAxis} />
              </TabPanel>
              <TabPanel>
                <AnomalyCircles anomalies={anomaliesYAxis} />
              </TabPanel>
            </Tabs>
          </div>
        </TabPanel>

        <TabPanel>
          <div className="anomaly-info">
            <p>
              Total Data:{" "}
              <strong style={{ color: "#c80036" }}>{anomaliesX.length}</strong>
            </p>
            <p>
              Anomaly Count:{" "}
              <strong style={{ color: "#c80036" }}>{anomalyCountX}</strong>
            </p>
          </div>

          <div className="map-container">
            <Tabs>
              <TabList>
                <div className="anomaly-tablist">
                  <Tab>Heatmap</Tab>
                  <Tab>Icons</Tab>
                  <Tab>Circles</Tab>
                </div>
              </TabList>

              <TabPanel>
                <AnomalyHeatmap anomalies={anomaliesXAxis} />
              </TabPanel>
              <TabPanel>
                <AnomalyMap anomalies={anomaliesXAxis} />
              </TabPanel>
              <TabPanel>
                <AnomalyCircles anomalies={anomaliesXAxis} />
              </TabPanel>
            </Tabs>
          </div>
        </TabPanel>
      </Tabs>

      <button className="chart-button" onClick={onClose}>
        CLOSE
      </button>
    </div>
  );
}

export default AnomalyResults;
