import { useState, useRef, useEffect } from "react";
import useAuth from "../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import axios from "../api/axios";
import Navbar from "../components/Navbar";
const LOGIN_URL = "/auth";

const Login = () => {
  // useContext hook'u kullanılarak AuthContext bağlamından setAuth fonksiyonunu çekmeyi sağlar.
  // Bu bağlamdan setAuth fonksiyonunu çekerek, Login bileşeni içinde kullanabilirsiniz.
  // useContext, useAuth hook'unun içinde kullanılarak global bir hook tanımlanmış oldu.
  const { setAuth } = useAuth();

  const [user, setUser] = useState("");
  const [pwd, setPwd] = useState("");
  const [errMsg, setErrMsg] = useState("");

  const navigate = useNavigate();
  // const location = useLocation();

  // 'location.state?.from?.pathname' ile kullanıcının geldiği sayfanın 'pathname' (URL'nin yolu) bilgisine erişilmeye çalışılır.
  // Eğer bu bilgi mevcut değilse veya 'undefined' ise varsayılan olarak '/' atanır.
  // from değişkeni, giriş yapmadan önceki sayfanın 'pathname' bilgisini içerir.
  // const from = location.state?.from?.pathname || "/";

  const userRef = useRef();
  const errRef = useRef();

  useEffect(() => {
    // komponent ilk yüklendiğinde input'a odaklanmak için kullanılır.
    userRef.current.focus();
  }, []);

  useEffect(() => {
    // kullanıcı adı ve parola değiştiğinde olası hata mesajını temizler.
    setErrMsg("");
  }, [user, pwd]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // authentication
    try {
      const response = await axios.post(
        LOGIN_URL,
        JSON.stringify({ user, pwd }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
      console.log(JSON.stringify(response?.data));
      const accessToken = response?.data?.accessToken;
      const roles = response?.data?.roles;
      setAuth({ user, pwd, roles, accessToken });
      setUser(""); // Reset Email
      setPwd(""); // Reset Password

      // { replace:true } kullanıcı geri tuşuna bastığında giriş sayfasına değil, giriş yapmadan önceki sayfaya döner.
      // Bu kullanım, kullanıcının giriş yapmadan önce bulunduğu sayfayı hatırlamak ve giriş yaptıktan sonra doğrudan o sayfaya yönlendirmek amacıyla yapılmıştır.
      // Bu, kullanıcı deneyimini artıran bir yönlendirme davranışıdır, çünkü kullanıcı giriş yaptıktan sonra hemen önceki sayfasına dönebilir.
      // navigate(from, { replace: true });

      navigate("/dashboard");
    } catch (err) {
      if (!err?.response) {
        setErrMsg("No Server Response");
      } else if (err.response?.status === 400) {
        setErrMsg("Missing Username or Password");
      } else if (err.response?.status === 401) {
        setErrMsg("Invalid username or password.");
      } else {
        setErrMsg("Login Failed");
      }
      errRef.current.focus();
    }
  };

  return (
    <>
      <Navbar />
      <div className="login-container">
        <div className="form-container">
          <p
            ref={errRef}
            className={errMsg ? "errmsg" : "offscreen"}
            aria-live="assertive"
          >
            {errMsg}
          </p>
          <h2>Login</h2>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              {/* <label htmlFor="user">Email</label> */}
              <input
                ref={userRef}
                autoComplete="off"
                id="username"
                type="text"
                onChange={(e) => setUser(e.target.value)}
                value={user}
                placeholder="Username or Email"
                required
              />
            </div>
            <div className="form-group">
              {/* <label htmlFor="pwd">Password</label> */}
              <input
                type="password"
                id="password"
                onChange={(e) => setPwd(e.target.value)}
                value={pwd}
                placeholder="Password"
                required
              />
            </div>
            <button>Login</button>
          </form>
        </div>
      </div>
    </>
  );
};

export default Login;
