import { CONTACT } from "../constants/advertiseData";
import { motion } from "framer-motion";

const AdvertiseContact = () => {
  return (
    <div className="adv-contact-border">
      <motion.h1
        whileInView={{ opacity: 1, y: 0 }}
        initial={{ opacity: 0, y: -100 }}
        transition={{ duration: 0.5 }}
        className="adv-contact-header-title"
      >
        Get in Touch
      </motion.h1>
      <div className="adv-contact-info">
        {/* <motion.p
          whileInView={{ opacity: 1, x: 0 }}
          initial={{ opacity: 0, x: -100 }}
          transition={{ duration: 1 }}
          className="adv-contact-details"
        >
          {CONTACT.address}
        </motion.p> */}
        {/* <motion.p
          whileInView={{ opacity: 1, x: 0 }}
          initial={{ opacity: 0, x: 100 }}
          transition={{ duration: 1 }}
          className="adv-contact-details"
        >
          {CONTACT.phoneNo}
        </motion.p> */}
        <p>{CONTACT.email}</p>
      </div>
    </div>
  );
};

export default AdvertiseContact;
