import AnomalyHero from "../components/AnomalyHero";

const Anomaly = () => {
  return (
    <div>
      <AnomalyHero />
    </div>
  );
};

export default Anomaly;
