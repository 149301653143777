import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";

import { FaBars, FaTimes } from "react-icons/fa";

const Navbar = () => {
  const [click, setClick] = useState(false);
  const [color, setColor] = useState(false);

  const handleClick = () => {
    setClick((clicking) => !clicking);
  };

  const changeColor = () => {
    // Sayfanın yatay eksen kaydırma değeri 100 pikselden büyük veya eşitse 'true' yapar.
    // Bu fonksiyon, kaydırma olayını dinleyerek belirli bir noktadan sonra arka plan rengini değiştirme işlevselliği ekler.
    if (window.scrollY >= 100) {
      setColor(true);
    } else {
      setColor(false);
    }
  };

  window.addEventListener("scroll", changeColor);

  return (
    <div className={color ? "header header-bg" : "header"}>
      {/* Header Title */}
      <Link to="/home">
        <h1>IMS</h1>
      </Link>

      {/* Right Navigation Titles */}

      <ul className={click ? "nav-menu active" : "nav-menu"}>
        <li>
          <Link to="/home">Home</Link>
        </li>
        <li>
          <Link to="/project">Project</Link>
        </li>
        <li>
          <Link to="/about">About</Link>
        </li>
        <li>
          <Link to="/contact">Contact</Link>
        </li>
      </ul>
      {/* Hamberger Icon */}
      <div className="hamburger" onClick={handleClick}>
        {click ? (
          <FaTimes size={20} style={{ color: "#fff" }} />
        ) : (
          <FaBars size={20} style={{ color: "#fff" }} />
        )}
      </div>
    </div>
  );
};

export default Navbar;
