import { useState, useEffect } from "react";

const OnlineStatusbar = () => {
  const [isOnline, setIsOnline] = useState(true);
  useEffect(() => {
    function handleOnline() {
      setIsOnline(true);
    }
    function handleOffline() {
      setIsOnline(false);
    }

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  return (
    <div
      style={{
        margin: "0.5rem auto",
        backgroundColor: "#edf2f4", // f0f3f5
        borderRadius: "0.5rem",
        padding: "0.5rem",
      }}
    >
      {isOnline
        ? "Internet Connection Available ✅"
        : "No Internet Connection ❌"}
    </div>
  );
};

export default OnlineStatusbar;
