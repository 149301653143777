import ProfileHeader from "./ProfileHeader";
import "./Profile.css";
import profileImg from "../assets/images/profile.png";
import { FaMicrochip } from "react-icons/fa6";
import { useContext } from "react";
import AuthContext from "../context/AuthProvider";

const DEVICE_CONFIG = [
  {
    title: "ID 867204060128758",
    attribute: "Location, Speed, Acceleration",
    icon: <FaMicrochip />,
  },
  {
    title: "ID 867204060495892",
    attribute: "Location, Speed, Acceleration",
    icon: <FaMicrochip />,
  },
];

const Profile = () => {
  const { auth } = useContext(AuthContext);

  return (
    <div className="profile">
      <ProfileHeader />

      <div className="user--profile">
        <div className="user--detail">
          <img src={profileImg} alt="profile-img" />
          <h3 className="username">{auth.user}</h3>
          {/* <span className="profession">{auth.roles}</span> */}
        </div>

        {/* Burası düşünülecek */}
        <div className="device-config">
          {DEVICE_CONFIG.map((itemConfig) => (
            <div className="config" key={itemConfig.title}>
              <div className="config-detail">
                <div className="config-cover">{itemConfig.icon}</div>
                <div className="config--name">
                  <h5 className="title">{itemConfig.title}</h5>
                  <span className="attribute">{itemConfig.attribute}</span>
                </div>
              </div>
              <div className="action">:</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Profile;
