import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";

import anomalyIcon from "../assets/icons/placeholder2.png";

const customIcon = new L.Icon({
  iconUrl: anomalyIcon,
  iconSize: [30, 30], // Icon size
  iconAnchor: [15, 45], // İkonun haritaya nasıl yerleştirileceği
  popupAnchor: [0, -45], // Popup'un ikona göre konumu
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
  shadowSize: [30, 30],
  shadowAnchor: [10, 45],
});

function AnomalyMap({ anomalies }) {
  return (
    <MapContainer
      center={[anomalies[0].latitude, anomalies[0].longitude]}
      zoom={8}
      style={{ height: "500px", width: "100%" }}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      {anomalies.map((anomaly, index) => (
        <Marker
          key={index}
          position={[anomaly.latitude, anomaly.longitude]}
          icon={customIcon}
        >
          <Popup>
            <strong>Anomaly {index + 1}</strong>
            <br />
            Value: {anomaly.value}
          </Popup>
        </Marker>
      ))}
    </MapContainer>
  );
}

export default AnomalyMap;
