// import { FcElectricalSensor } from "react-icons/fc";
// import { FcOvertime } from "react-icons/fc";
import { IoSpeedometer } from "react-icons/io5";
import { GiMountaintop } from "react-icons/gi";
import { TbClockPin } from "react-icons/tb";

const CARD_DATA = [
  {
    title: "Time",
    icon: <TbClockPin />,
    value: "locationTime",
  },
  {
    title: "Altitude",
    icon: <GiMountaintop />,
    value: "altitude",
  },
  {
    title: "Speed",
    icon: <IoSpeedometer />,
    value: "speed",
  },
];

const Card = ({ location, locationTime, color }) => {
  return (
    <div className="card--container">
      {CARD_DATA.map((item) => (
        <div className="card" key={item.title}>
          <div className="card--cover">{item.icon}</div>
          <div className="card--title">
            <p className="card--desc">{item.title}</p>
            <h2 style={{ color: color }}>
              {item.value === "locationTime"
                ? locationTime
                : location[item.value]}
            </h2>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Card;
