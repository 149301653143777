import React, { useEffect, useState } from "react";
import { useTable, useSortBy, usePagination } from "react-table";
import * as XLSX from "xlsx";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./Reports.css";
import tr from "date-fns/locale/tr";
import { ClipLoader } from "react-spinners";
import MapExample from "../components/MapExample";
import MapExampleX from "../components/MapExampleX";
import MapExampleY from "../components/MapExampleY";
import MapExampleZ from "../components/MapExampleZ";
import Modal from "react-modal";
import MyColorScale from "../components/ColorScale";

const columns = [
  // {
  //   Header: "ID",
  //   accessor: "timestampID",
  // },
  // {
  //   Header: "DEVICE",
  //   accessor: "ident",
  // },
  {
    Header: "X",
    accessor: "maxX",
  },
  {
    Header: "Y",
    accessor: "maxY",
  },
  {
    Header: "Z",
    accessor: "maxZ",
  },
  {
    Header: "Lat",
    accessor: "latitude",
  },
  {
    Header: "Long",
    accessor: "longitude",
  },
  {
    Header: "TIME",
    accessor: "timestamp",
  },
  {
    Header: "Map",
    accessor: "map", // Harita göster butonu için yeni bir sütun
  },
];

const maxXYZData = (dataArray) => {
  const updatedDataArray = dataArray.map((element) => {
    const accArray = element.acceleration;
    const absXValues = accArray.map((accItem) => Math.abs(accItem.x));
    const absYValues = accArray.map((accItem) => Math.abs(accItem.y));
    const absZValues = accArray.map((accItem) => Math.abs(accItem.z));

    const maxX = Math.max(...absXValues);
    const maxY = Math.max(...absYValues);
    const maxZ = Math.max(...absZValues);

    return {
      ...element,
      maxX,
      maxY,
      maxZ,
      map: (
        <button
          className="detail-coordinate"
          onClick={() => handleShowMap(element.latitude, element.longitude)}
        >
          Show on Map
        </button>
      ),
    };
  });

  const handleShowMap = (latitude, longitude) => {
    const mapUrl = `https://www.openstreetmap.org/?mlat=${latitude}&mlon=${longitude}`;

    // Yeni bir pencerede haritayı göster
    window.open(mapUrl, "_blank");
  };

  const updatedDataTime = updatedDataArray.map((element, index) => {
    const options = {
      timeZone: "Europe/Istanbul",
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    };
    return {
      ...element,
      timestamp: new Date(element.timestamp * 1000).toLocaleDateString(
        "tr-TR",
        options
      ),
    };
  });

  console.log(updatedDataTime);

  return updatedDataTime;
};

const Reports = () => {
  const [tableData, setTableData] = useState([]);
  const [selectedInputTime, setSelectedInputTime] = useState("");
  const [selectedOutputTime, setSelectedOutputTime] = useState("");
  const [inputTimestamp, setInputTimeStamp] = useState("");
  const [outputTimestamp, setOutputTimeStamp] = useState("");
  const [device, setDevice] = useState("Device_5892");
  const [isLoading, setIsLoading] = useState(true);

  // Modal state
  const [averageModalIsOpen, setAverageModalIsOpen] = useState(false);
  const [xModalIsOpen, setxModalIsOpen] = useState(false);
  const [yModalIsOpen, setyModalIsOpen] = useState(false);
  const [zModalIsOpen, setzModalIsOpen] = useState(false);

  const handleDevice = (event) => {
    setDevice(event.target.value);
  };

  const convertToTimestamp = (date) => {
    return date instanceof Date ? date.getTime() / 1000 : null;
  };

  const clearTime = () => {
    setSelectedInputTime("");
    setSelectedOutputTime("");
    setInputTimeStamp("");
    setOutputTimeStamp("");
  };

  const handleInputTimeChange = (date) => {
    setSelectedInputTime(date);
    setInputTimeStamp(convertToTimestamp(date));
  };

  const handleOutputTimeChange = (date) => {
    setSelectedOutputTime(date);
    setOutputTimeStamp(convertToTimestamp(date));
  };

  const exportToExcel = (data, filename = "ReportData") => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, `${filename}.xlsx`);
  };
  const handleXShowHeatmap = () => {
    setxModalIsOpen(true);
  };

  const handleYShowHeatmap = () => {
    setyModalIsOpen(true);
  };

  const handleZShowHeatmap = () => {
    setzModalIsOpen(true);
  };

  const handleAverageShowHeatmap = () => {
    setAverageModalIsOpen(true);
  };

  const API_URL = `https://ims-backend-6yyp.onrender.com/${device}/${inputTimestamp}/${outputTimestamp}/combinedData`;

  useEffect(() => {
    const fetchItems = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(API_URL);
        if (!response.ok) throw Error("Did not receive expected data");
        const listItems = await response.json();
        const dataArray = listItems.data;

        if (dataArray && dataArray.length > 0) {
          setTableData(maxXYZData(dataArray));
        } else {
          setTableData([]);
        }
        setIsLoading(false);
      } catch (err) {
        console.log(err);
        setTableData([]);
        setIsLoading(false);
      }
    };

    fetchItems();
  }, [API_URL]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    state: { pageIndex },
    pageCount,
    gotoPage,
  } = useTable(
    {
      columns,
      data: tableData,
    },
    useSortBy,
    usePagination
  );

  return (
    <>
      <div className="container">
        <div className="controls-container">
          <button
            className="export-button"
            onClick={() => exportToExcel(tableData, "ReportData")}
          >
            Export to Excel
          </button>

          <div className="date-picker-container">
            <label>Device:</label>
            <select
              className="select-device"
              onChange={(event) => handleDevice(event)}
              value={device}
            >
              <option value="Device_5892">65007</option>
              <option value="Device_2495">65004</option>
              {/* <option value="65009">65009</option> */}
            </select>

            <label>Input time: </label>
            <DatePicker
              selected={selectedInputTime}
              onChange={handleInputTimeChange}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              timeCaption="Time"
              dateFormat="MMMM d, yyyy h:mm aa"
              locale={tr}
            />

            <label>Output time: </label>
            <DatePicker
              selected={selectedOutputTime}
              onChange={handleOutputTimeChange}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              timeCaption="Time"
              dateFormat="MMMM d, yyyy h:mm aa"
              locale={tr}
            />

            <button className="clear-button" onClick={clearTime}>
              Clear
            </button>
          </div>
        </div>

        {selectedInputTime && selectedOutputTime ? (
          <>
            {isLoading ? (
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <ClipLoader color="#a8dadc" size={35} />
              </div>
            ) : (
              <div>
                <table id="example" {...getTableProps()}>
                  <thead>
                    {headerGroups.map((hg) => (
                      <tr {...hg.getHeaderGroupProps()}>
                        {hg.headers.map((column) => (
                          <th
                            {...column.getHeaderProps(
                              column.getSortByToggleProps()
                            )}
                          >
                            {column.render("Header")}
                            {column.isSorted && (
                              <span>{column.isSortedDesc ? " 🔽" : " 🔼"}</span>
                            )}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {page.map((row) => {
                      prepareRow(row);

                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map((cell) => (
                            <td {...cell.getCellProps()}>
                              {cell.render("Cell")}
                            </td>
                          ))}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>

                <div className="btn-container">
                  <button
                    disabled={pageIndex === 0}
                    onClick={() => gotoPage(0)}
                  >
                    First
                  </button>
                  <button disabled={!canPreviousPage} onClick={previousPage}>
                    Prev
                  </button>
                  <span>
                    {pageIndex + 1} of {pageCount}
                  </span>
                  <button disabled={!canNextPage} onClick={nextPage}>
                    Next
                  </button>
                  <button
                    disabled={pageIndex >= pageCount - 1}
                    onClick={() => gotoPage(pageCount - 1)}
                  >
                    Last
                  </button>
                  <button
                    style={{
                      backgroundColor: "#9d0208",
                      padding: "0.25rem 0.5rem",
                      cursor: "pointer",
                    }}
                    onClick={handleXShowHeatmap}
                  >
                    X Heatmap
                  </button>
                  <button
                    style={{
                      backgroundColor: "#9d0208",
                      padding: "0.25rem 0.5rem",
                      cursor: "pointer",
                    }}
                    onClick={handleYShowHeatmap}
                  >
                    Y Heatmap
                  </button>

                  <button
                    style={{
                      backgroundColor: "#9d0208",
                      padding: "0.25rem 0.5rem",
                      cursor: "pointer",
                    }}
                    onClick={handleZShowHeatmap}
                  >
                    Z Heatmap
                  </button>

                  <button
                    style={{
                      backgroundColor: "#9d0208",
                      padding: "0.25rem 0.5rem",
                      cursor: "pointer",
                    }}
                    onClick={handleAverageShowHeatmap}
                  >
                    Average Heatmap
                  </button>
                </div>
              </div>
            )}
          </>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: "2rem",
            }}
          >
            <p style={{ color: "#393d3f" }}>Please Enter Time</p>
          </div>
        )}
      </div>
      {/* Average heatmap */}
      <Modal
        isOpen={averageModalIsOpen}
        onRequestClose={() => setAverageModalIsOpen(false)}
        contentLabel="Heatmap Modal"
        style={{
          content: {
            position: "absolute",
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            maxHeight: "75%", // Sayfanın yarısını kaplaması için
            width: "75%",
            overflow: "auto", // Modal içeriği aşırı taşarsa kaydırma çubuğunu göster
          },
        }}
      >
        {/* Modal içeriği */}
        <div>
          <div className="heatmap-modal-container">
            <h2>Average Acceleration Heatmap Analysis</h2>
            <button
              className="heatmap-modal-button"
              onClick={() => setAverageModalIsOpen(false)}
            >
              Close
            </button>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              margin: "0.5rem",
            }}
          >
            <p
              style={{
                color: "#000",
                marginRight: "1rem",
                marginBottom: "0.25rem",
                fontWeight: "bolder",
              }}
            >
              Color Scale (Ascending)
            </p>
            <MyColorScale />
          </div>
          {/* MapExample bileşeni */}
          {tableData.length !== 0 && (
            <div id="map">
              <MapExample heatmapData={tableData} />
            </div>
          )}
        </div>
      </Modal>

      {/* X heatmap */}
      <Modal
        isOpen={xModalIsOpen}
        onRequestClose={() => setxModalIsOpen(false)}
        contentLabel="Heatmap Modal"
        style={{
          content: {
            position: "absolute",
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            maxHeight: "75%", // Sayfanın yarısını kaplaması için
            width: "75%",
            overflow: "auto", // Modal içeriği aşırı taşarsa kaydırma çubuğunu göster
          },
        }}
      >
        {/* Modal content */}
        <div>
          <div className="heatmap-modal-container">
            <h2>X-Axis Heatmap Analysis</h2>

            <button
              className="heatmap-modal-button"
              onClick={() => setxModalIsOpen(false)}
            >
              Close
            </button>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              margin: "0.5rem",
            }}
          >
            <p
              style={{
                color: "#000",
                marginRight: "1rem",
                marginBottom: "0.25rem",
              }}
            >
              Color Scale (Ascending)
            </p>
            <MyColorScale />
          </div>

          {/* MapExample component */}
          {tableData.length !== 0 && (
            <div id="map">
              <MapExampleX heatmapData={tableData} />
            </div>
          )}
        </div>
      </Modal>

      {/* Y heatmap */}
      <Modal
        isOpen={yModalIsOpen}
        onRequestClose={() => setyModalIsOpen(false)}
        contentLabel="Heatmap Modal"
        style={{
          content: {
            position: "absolute",
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            maxHeight: "75%", // Sayfanın yarısını kaplaması için
            width: "75%",
            overflow: "auto", // Modal içeriği aşırı taşarsa kaydırma çubuğunu göster
          },
        }}
      >
        {/* Modal content */}
        <div>
          <div className="heatmap-modal-container">
            <h2>Y-Axis Heatmap Analysis</h2>
            <button
              className="heatmap-modal-button"
              onClick={() => setyModalIsOpen(false)}
            >
              Close
            </button>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              margin: "0.5rem",
            }}
          >
            <p
              style={{
                color: "#000",
                marginRight: "1rem",
                marginBottom: "0.25rem",
              }}
            >
              Color Scale (Ascending)
            </p>
            <MyColorScale />
          </div>

          {/* MapExample component */}
          {tableData.length !== 0 && (
            <div id="map">
              <MapExampleY heatmapData={tableData} />
            </div>
          )}
        </div>
      </Modal>

      {/* Z heatmap */}
      <Modal
        isOpen={zModalIsOpen}
        onRequestClose={() => setzModalIsOpen(false)}
        contentLabel="Heatmap Modal"
        style={{
          content: {
            position: "absolute",
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            maxHeight: "75%", // Sayfanın yarısını kaplaması için
            width: "75%",
            overflow: "auto", // Modal içeriği aşırı taşarsa kaydırma çubuğunu göster
          },
        }}
      >
        {/* Modal content */}
        <div>
          <div className="heatmap-modal-container">
            <h2>Z-Axis Heatmap Analysis</h2>

            <button
              className="heatmap-modal-button"
              onClick={() => setzModalIsOpen(false)}
            >
              Close
            </button>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              margin: "0.5rem",
            }}
          >
            <p
              style={{
                color: "#000",
                marginRight: "1rem",
                marginBottom: "0.25rem",
              }}
            >
              Color Scale (Ascending)
            </p>

            <MyColorScale />
          </div>

          {/* MapExample component */}
          {tableData.length !== 0 && (
            <div id="map">
              <MapExampleZ heatmapData={tableData} />
            </div>
          )}
        </div>
      </Modal>
      {/* {heatmapShow && tableData.length !== 0 && (
        <div
          id="map"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "50vh",
            width: "100%",
          }}
        >
          <MapExample heatmapData={tableData} />
        </div>
      )} */}
      <div className="reports-footer">
        <p className="footer-p">
          Copyright {new Date().getFullYear()}  ©  TMS R&D. All Rights Reserved.
        </p>
      </div>
    </>
  );
};

export default Reports;
