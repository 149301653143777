import AdvertiseHero from "../components/AdvertiseHero";
import AdvertiseAbout from "../components/AdvertiseAbout";
import AdvertiseFeatures from "../components/AdvertiseFeatures";
import AdvertiseContact from "../components/AdvertiseContact";
import AdvertisePartners from "../components/AdvertisePartners";

const Attribute = () => {
  return (
    <div className="adv-page-settings">
      <div className="adv-selection">
        <div className="adv-fixed-background"></div>
        <div className="adv-absolute-background"></div>
      </div>
      <div className="adv-container">
        <AdvertiseHero />
        <AdvertiseAbout />
        <AdvertiseFeatures />
        <AdvertisePartners />
        <AdvertiseContact />
      </div>
    </div>
  );
};

export default Attribute;
