import Card from "./Card";
import "./Content.css";
import ContentHeader from "./ContentHeader";
import DeviceList from "./DeviceList";
import { ClipLoader } from "react-spinners";
import OnlineStatusbar from "./OnlineStatusbar";

const Content = ({
  device,
  setDevice,
  location,
  locationTime,
  isLoading,
  setIsLoading,
  color,
}) => {
  return (
    <div className="content">
      {isLoading ? (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <ClipLoader color="#36D7B7" size={35} />
        </div>
      ) : (
        <>
          <ContentHeader
            device={device}
            setDevice={setDevice}
            setIsLoading={setIsLoading}
          />
          <Card location={location} locationTime={locationTime} color={color} />
          <OnlineStatusbar />
          <DeviceList location={location} />
        </>
      )}
    </div>
  );
};

export default Content;
