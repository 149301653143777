import "./NewState.css";
import { useEffect, useRef } from "react";
import Modal from "./Modal";

const NewState = ({ onCancel }) => {
  const modal = useRef();

  const destination = useRef();
  const device = useRef();
  const input = useRef();
  const output = useRef();
  const inputDest = useRef();
  const outputDest = useRef();
  const dailyDat = useRef();

  useEffect(() => {});

  const handleSave = async () => {
    // retrieve values from inputs
    const enteredDestinationNo = destination.current.value;
    const enteredDeviceName = device.current.value;
    const enteredInputTime = input.current.value;
    const enteredOutputTime = output.current.value;
    const enteredInputDestination = inputDest.current.value;
    const enteredOutputDestination = outputDest.current.value;
    const enteredDailyDate = dailyDat.current.value;

    // validation
    if (
      enteredDestinationNo.trim() === "" ||
      enteredDeviceName.trim() === "" ||
      enteredInputTime.trim() === "" ||
      enteredOutputTime.trim() === "" ||
      enteredInputDestination.trim() === "" ||
      enteredOutputDestination.trim() === "" ||
      enteredDailyDate.trim() === ""
    ) {
      // show error modal
      modal.current.open();
      return; // exist
    }

    const newStateData = {
      destinationNo: enteredDestinationNo,
      deviceName: enteredDeviceName,
      inputTime: enteredInputTime,
      outputTime: enteredOutputTime,
      inputDestination: enteredInputDestination,
      outputDestination: enteredOutputDestination,
      dailyDate: enteredDailyDate,
    };

    try {
      const response = await fetch(
        "https://ims-backend-6yyp.onrender.com/dailystate",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(newStateData),
        }
      );

      if (!response.ok) {
        throw new Error(`Http error! Status: ${response.status}`);
      }

      const enteredState = await response.json();
      console.log("Sending is delivered: ", enteredState);
      // Users Feedback Information
    } catch (error) {
      console.error("Error: ", error.messages);
      // Users Error Feedback
    }

    onCancel();
  };

  return (
    <>
      <Modal ref={modal} buttonCaption="Close">
        <h2 className="modal-header">Invalid Input</h2>
        <p className="modal-paragraph">Make sure you provide a valid value.</p>
      </Modal>
      <div className="new-state-container">
        <menu className="state-button-container">
          <button onClick={onCancel} className="state-button">
            Cancel
          </button>
          <button onClick={handleSave} className="state-save-button">
            Save
          </button>
        </menu>
        <div className="input-group">
          <p className="input-container">
            <label className="label-style">
              Destination No (Sefer Numarası)
            </label>
            <input ref={destination} type="text" className="input-style" />
          </p>
          <p className="input-container">
            <label className="label-style">Device Name (Set)</label>
            <input ref={device} type="text" className="input-style" />
          </p>
          <p className="input-container">
            <label className="label-style">
              Input Time (Sefer Başlangıç Saati)
            </label>
            <input ref={input} type="time" className="input-style" />
          </p>
          <p className="input-container">
            <label className="label-style">
              Output Time (Sefer Bitiş Saati)
            </label>
            <input ref={output} type="time" className="input-style" />
          </p>
          <p className="input-container">
            <label className="label-style">
              Input Destination (Kalkış Yeri)
            </label>
            <input ref={inputDest} type="text" className="input-style" />
          </p>
          <p className="input-container">
            <label className="label-style">
              Output Destination (Varış Yeri)
            </label>
            <input ref={outputDest} type="text" className="input-style" />
          </p>
          <p className="input-container">
            <label className="label-style">Date (Tarih)</label>
            <input ref={dailyDat} type="date" className="input-style" />
          </p>
        </div>
      </div>
    </>
  );
};

export default NewState;
