// import {
//   APIProvider,
//   Map,
//   AdvancedMarker,
//   Pin,
//   InfoWindow,
// } from "@vis.gl/react-google-maps";

// import { useState } from "react";

// const MapComponent2 = ({ location }) => {
//   const [open, setOpen] = useState(false);
//   // location prop'u geçerli bir değere sahipse
//   if (!location || !location.latitude || !location.longitude) {
//     // Konum bilgileri eksik olduğu için bir hata mesajı veya yedek içerik gösterebilirsiniz
//     return <div>Location data is missing</div>;
//   }
//   const position = { lat: location.latitude, lng: location.longitude };

//   return (
//     <APIProvider apiKey={process.env.REACT_APP_API_KEY}>
//       <div
//         style={{
//           height: "300px",
//         }}
//       >
//         <Map
//           zoom={10}
//           center={position}
//           gestureHandling={"greedy"}
//           disableDefaultUI={true}
//           mapId={process.env.REACT_APP_MAP_ID}
//           style={{ borderRadius: "20px" }}
//         >
//           {/* Marker */}
//           <AdvancedMarker position={position} onClick={() => setOpen(true)}>
//             {/* Changing Looks Like */}

//             <Pin
//               background={"DarkRed"}
//               borderColor={"black"}
//               glyphColor={"azure"}
//             />
//           </AdvancedMarker>

//           {open && (
//             <InfoWindow position={position} onCloseClick={() => setOpen(false)}>
//               <p
//                 style={{
//                   color: "black",
//                   fontWeight: "bold",
//                 }}
//               >
//                 Speed: {location.speed}
//               </p>
//             </InfoWindow>
//           )}
//         </Map>
//       </div>
//     </APIProvider>
//   );
// };

// export default MapComponent2;

import { useState, useEffect } from "react";
import L from "leaflet";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";

import trainHome from "../assets/images/train-home-removebg.png";
import ChangeView from "./ChangeView";

const MapComponent2 = ({ location }) => {
  const [mapPosition, setMapPosition] = useState([
    location.latitude,
    location.longitude,
  ]);

  useEffect(() => {
    // location bilgisini güncelle
    setMapPosition([location.latitude, location.longitude]);
  }, [location]);

  if (!location || !location.latitude || !location.longitude) {
    // Konum bilgileri eksik olduğu için bir hata mesajı veya yedek içerik gösterebilirsiniz
    return <div>Location data is missing</div>;
  }

  const customMarkerIcon = L.icon({
    iconUrl: trainHome,
    iconSize: [44, 44], // marker boyutu
    iconAnchor: [16, 32], // marker'ın orta noktası
    popupAnchor: [0, -32], // popup'ın alt kenarı
  });

  return (
    <div style={{ height: "400px" }}>
      <MapContainer
        style={{ height: "100%", borderRadius: "20px" }}
        center={mapPosition}
        zoom={13}
        scrollWheelZoom={true}
      >
        <ChangeView center={mapPosition} zoom={13} />
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png"
        />
        <Marker position={mapPosition} icon={customMarkerIcon}>
          <Popup>
            <div>ID: {location.ident}</div>
            <div>Speed: {location.speed}</div>
          </Popup>
        </Marker>
      </MapContainer>
    </div>
  );
};

export default MapComponent2;
