import { useEffect, useState } from "react";

import DailyStateSidebar from "../components/DailyStateSidebar";
import NewState from "../components/NewState";
import NoStateSelected from "../components/NoStateSelected";
import SelectedDailystate from "../components/SelectedDailystate";
import axios from "axios";

const DailyState = () => {
  const [projectSelected, setProjectSelected] = useState(undefined);
  const [stateData, setStateData] = useState([]);
  const [fetchDataError, setFetchDataError] = useState(null);
  const [loading, setLoading] = useState(true);

  const STATE_API = "https://ims-backend-6yyp.onrender.com/dailystate";

  const handleStartProject = () => {
    setProjectSelected(null);
  };

  const handleCloseProject = () => {
    setProjectSelected(undefined);
  };

  const handleSelectState = (id) => {
    setProjectSelected(id);
  };

  const handleDeleteState = () => {
    axios
      .delete(`https://ims-backend-6yyp.onrender.com/dailystate/`, {
        data: { id: projectSelected },
      })
      .then(() => {
        console.log(`State with ID ${projectSelected} deleted`);
        // After successful deletion, update stateData and reset projectSelected
        setProjectSelected(undefined);
      })
      .catch((error) => {
        console.error("Error deleting state:", error);
      });
  };

  useEffect(() => {
    const fetchState = async () => {
      try {
        const response = await fetch(STATE_API);
        if (!response.ok) throw Error("Did not receive expected data");
        const stateItems = await response.json();
        console.log(stateItems);

        setStateData(stateItems);
        setFetchDataError(null);
      } catch (err) {
        setFetchDataError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchState();
  }, [projectSelected]);

  console.log(projectSelected);

  const selectedState = stateData.find(
    (state) => state._id === projectSelected
  );

  let content = (
    <SelectedDailystate
      state={selectedState}
      onDelete={handleDeleteState}
      onCancel={handleCloseProject}
    />
  );

  if (projectSelected === null) {
    content = <NewState onCancel={handleCloseProject} />;
  } else if (projectSelected === undefined) {
    content = (
      <NoStateSelected
        allStateData={stateData}
        onStartProject={handleStartProject}
        onSelectState={handleSelectState}
      />
    );
  }

  return (
    <main className="daily-state-container">
      <DailyStateSidebar
        statesData={stateData}
        onStartProject={handleStartProject}
        onSelectState={handleSelectState}
      />
      {content}
    </main>
  );
};

export default DailyState;
