// import partner1 from "../assets/partners/logo.jpg";
// import partnerTCDD from "../assets/partners/Tcdd_logo.jpg";
import partnerTCDD from "../assets/partners/TCDD_tasimacilik.png";

export const HERO_CONTENT =
  "Interactive Mobile System (IMS) is a project that utilizes sensor technologies to collect real-time critical data such as location, vibration, and speed, and interprets this data to provide real-time solutions to users. IMS is designed to enhance the efficiency, ensure the safety, and optimize the performance of mobile systems.";

export const ABOUT_TEXT = `Our cutting-edge IoT sensor device is designed to provide comprehensive real-time data analysis and tracking for a variety of applications. It continuously monitors and reports precise location data through real-time location tracking, ensuring accurate positional information. The device also detects and analyzes vibrations, offering insights into the operational status and potential issues of machinery and equipment. Additionally, it measures and reports speed, allowing for detailed monitoring of movement and performance. The heatmap analysis feature visualizes data to highlight areas of high activity or concern, aiding in the identification of patterns and anomalies. This versatile IoT sensor device is integral to the Interactive Mobile System (IMS), enabling the collection and interpretation of critical data to optimize performance, enhance efficiency, and ensure the safety of mobile systems.`;

export const PARTNERS = [
  {
    title: "TCDD",
    image: partnerTCDD,
    description:
      "We are proud to collaborate with TCDD to enhance their railway systems using our Interactive Mobile System (IMS). This partnership leverages our IoT sensor technology for real-time location tracking, vibration and speed analysis, and heatmap visualizations, aiming to improve operational efficiency and safety.",
  },
];

export const CONTACT = {
  address: "Adress",
  // phoneNo: "Phone",
  email: "argetms@gmail.com",
};
